import React from "react";
import "./style.css";
import tiltArrow from "../../assets/img/tiltArrow.svg";

const CustomButton = ({ customClasses, btnText, onClick }) => {
  return (
    <div className="btn-wrapper">
      <button
        type="button"
        className={`btn custom-btn ${customClasses}`}
        onClick={onClick}
      >
        {btnText}
        <img src={tiltArrow} alt="logo" />
      </button>
    </div>
  );
};

export default CustomButton;
