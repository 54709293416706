import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import CustomLink from "../components/CustomButton/CustomLink";
import OuterFooter from "../components/Footer/OuterFooter";

const PrivacyPolicy = () => {
  const [mobNavbar, setMobNavbar] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header mobNavbar={mobNavbar} setMobNavbar={setMobNavbar} />
      <section className="priTos-wrapper mb-5">
        <div className="container px-0">
          <div className="card priTos-card">
            <div className="card-header px-4 py-3 d-flex align-items-center">
              <h2 className="title">Privacy Policy</h2>
            </div>
            <div className="card-body px-4 py-3">
              <h3 className="card-title">Effective Date: August 30, 2024</h3>
              <p className="card-text">
                At Neurobridge Tech Private Limited ("Neurobridge," "we," "our," or "us"), we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share personal information when you interact with our website, applications, and services (collectively, "Services"). By using our Services, you agree to the collection and use of information in accordance with this policy.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">1. Personal Information We Collect</h3>
              <p className="card-text">
                <strong>Personal Information You Provide:</strong>
              </p>
              <p className="card-text ps-4">
                <strong>Account Information:</strong> When you create an account with us, we collect information associated with your account, including your name, contact information, account credentials, payment card information, and transaction history.
              </p>
              <p className="card-text ps-4">
                <strong>User Content:</strong> We collect personal information that you include in the input, file uploads, or feedback you provide to our Services.
              </p>
              <p className="card-text ps-4">
                <strong>Communication Information:</strong> If you communicate with us, we collect your name, contact information, and the contents of any messages you send.
              </p>
              <p className="card-text ps-4">
                <strong>Social Media Information:</strong> We may collect personal information when you interact with our social media pages on platforms like Instagram, Facebook, LinkedIn, etc.
              </p>
              <div className="py-2"></div>
              <p className="card-text">
                <strong>Personal Information We Receive Automatically:</strong>
              </p>
              <p className="card-text ps-4">
                <strong>Log Data:</strong> Information that your browser or device automatically sends when you use our Services, such as your Internet Protocol (IP) address, browser type and settings, the date and time of your request, and how you interact with our Services.
              </p>
              <p className="card-text ps-4">
                <strong>Usage Data:</strong> We may automatically collect information about your use of the Services, such as the types of content you view or engage with, the features you use, and the actions you take.
              </p>
              <p className="card-text ps-4">
                <strong>Device Information:</strong> We collect information about the device you are using, such as its name, operating system, device ID, device identifiers, and browser type.
              </p>
              <p className="card-text ps-4">
                <strong>Cookies:</strong> We use cookies to operate and administer our Services and improve your experience. You can control cookie settings through your browser.
              </p>
              <p className="card-text ps-4">
                <strong>Analytics:</strong> We may use online analytics tools to help us analyze how users use our Services and enhance your experience.
              </p>
              <div className="py-2"></div>
              <p className="card-text">
                <strong>Payment Information:</strong>
              </p>
              <p className="card-text ps-4">
                <strong>Payment Processors:</strong> We may collect payment information, such as credit or debit card details, through third-party payment processors to facilitate our Services. These processors are responsible for the secure handling of your payment information and operate under their own privacy policies.
              </p>
              <div className="py-2"></div>
              <p className="card-text">
                <strong>Third-Party Authentication Services:</strong>
              </p>
              <p className="card-text ps-4">
                <strong>Authentication Data:</strong> If you choose to sign in using third-party authentication services such as Google, Auth0, Apple Sign-In, or others, these services may collect personal information such as your name, email address, and other account details. This information may be shared with us to facilitate your access to our Services. The use and handling of this data by these third-party services are governed by their own privacy policies.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">2. How We Use Personal Information</h3>
              <p className="card-text">
                We may use your personal information for the following purposes:
              </p>
              <p className="card-text ps-4">
                <strong>Providing Services:</strong> To operate, maintain, and improve our Services, including the Chanakya AI application.
              </p>
              <p className="card-text ps-4">
                <strong>Communication:</strong> To send you updates, service announcements, promotional materials, and other relevant information.
              </p>
              <p className="card-text ps-4">
                <strong>Personalization:</strong> To tailor your experience based on your preferences and interactions with our Services.
              </p>
              <p className="card-text ps-4">
                <strong>Security:</strong> To protect against unauthorized access, fraud, and other malicious activities.
              </p>
              <p className="card-text ps-4">
                <strong>Compliance:</strong> To comply with legal obligations, enforce our Terms of Service, and protect our rights, privacy, safety, or property, and that of our affiliates, you, or others.
              </p>
              <p className="card-text ps-4">
                <strong>Aggregated or De-Identified Information:</strong> We may aggregate or de-identify personal information so that it cannot be used to identify you. We may use such information for analysis, research, and to improve our Services.
              </p>
              <p className="card-text ps-4">
                <strong>Model Training and Service Improvement:</strong> Data collected from users, after the removal of Personally Identifiable Information (PII), may be used for training our models or for making our services better. This helps us enhance the accuracy and effectiveness of the services we provide.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">3. Disclosure of Personal Information</h3>
              <p className="card-text">
                We may share your personal information in the following circumstances:
              </p>
              <p className="card-text ps-4">
                <strong>Vendors and Service Providers:</strong> To assist us in meeting business operations needs and performing certain services and functions, we may provide personal information to vendors and service providers, such as payment processors, hosting services, and web analytics services.
              </p>
              <p className="card-text ps-4">
                <strong>Business Transfers:</strong> In connection with mergers, acquisitions, or asset sales, your information may be transferred as part of the transaction.
              </p>
              <p className="card-text ps-4">
                <strong>Legal Requirements:</strong> We may share your personal information if required to do so by law, to protect our rights or property, to enforce our terms, or to protect the safety of our users and the public.
              </p>
              <p className="card-text ps-4">
                <strong>Affiliates:</strong> We may disclose personal information to our affiliates, who may use it in a manner consistent with this Privacy Policy.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">4. Your Rights</h3>
              <p className="card-text">
                Depending on your location, you may have certain rights regarding your personal information, including:
              </p>
              <p className="card-text ps-4">
                <strong>Access and Correction:</strong> The right to access and correct your personal information.
              </p>
              <p className="card-text ps-4">
                <strong>Deletion:</strong> The right to request the deletion of your personal information.
              </p>
              <p className="card-text ps-4">
                <strong>Data Portability:</strong> The right to request a copy of your personal information in a portable format.
              </p>
              <p className="card-text ps-4">
                <strong>Objection and Restriction:</strong> The right to object to or request the restriction of processing your personal information.
              </p>
              <p className="card-text ps-4">
                <strong>Withdrawal of Consent:</strong> Where we rely on consent, you may withdraw it at any time.
              </p>
              <p className="card-text ps-4">
                To exercise these rights, please contact us at support@neurobridge.tech.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">5. Children</h3>
              <p className="card-text">
                Our Services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have collected such information, please contact us at support@neurobridge.tech.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">6. Links to Other Websites</h3>
              <p className="card-text">
                Our Services may contain links to other websites not operated or controlled by us. The information you share with these third-party sites will be governed by their specific privacy policies and terms of service. We are not responsible for the privacy practices of these third parties.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">7. Security and Retention</h3>
              <p className="card-text">
                We implement commercially reasonable technical, administrative, and organizational measures to protect personal information from unauthorized access, misuse, and disclosure. However, no Internet or email transmission is ever fully secure, so please take care in deciding what information you send to us.
              </p>
              <p className="card-text">
                We will retain your personal information only as long as necessary to provide our Services to you or for other legitimate business purposes, such as resolving disputes or complying with legal obligations.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">8. International Users</h3>
              <p className="card-text">
                By using our Services, you understand and acknowledge that your personal information may be processed and stored in facilities and servers outside your country of residence. We ensure appropriate safeguards are in place to protect your information when transferred internationally.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">9. Changes to This Policy</h3>
              <p className="card-text">
                We may update this Privacy Policy from time to time. Any changes will be posted on this page, and significant changes will be notified to you as required by law. Your continued use of the Services after any changes constitutes your acceptance of the revised Privacy Policy.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">10. How to Contact Us</h3>
              <p className="card-text">
                If you have any questions or concerns about this Privacy Policy, please contact us at support@neurobridge.tech.
              </p>
            </div>
            <div className="card-footer px-4 py-3">
              <CustomLink
                linkText="Contact Us"
                LinkTo={"mailto:support@neurobridge.tech"}
              />
            </div>
          </div>
        </div>
      </section>
      <OuterFooter />
    </div>
  );
};

export default PrivacyPolicy;
