import React from "react";
import "./style.css";

const Faq = ({ faqQuestion }) => {
  return (
    <div className="container">
      <div className="faq-cardMain">
        <h5 className="leading-mark">FAQ'S</h5>
        <h3 className="landing-title">Everything you need to know</h3>
        <div className="accordion faq-accordion-main" id="accordionExample">
          {faqQuestion?.map((item) => (
            <div className="accordion-item">
              <h2
                className="accordion-header collapsed"
                data-bs-target={`#collapse${item.id}`}
                //   aria-expanded="true"
                aria-controls={`collapse${item.id}`}
                data-bs-toggle="collapse"
                id={`heading${item.id}`}
              >
                {item?.question}
                <span className="acc-button"></span>
              </h2>
              <div
                id={`collapse${item.id}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${item.id}`}
                data-bs-parent="#accordionExample"
              >
                {item?.answere}
                {item?.list && (
                  <ul>
                    {item?.list?.map((points) => (
                      <li>{`${points.heading} ${points.content}`}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
