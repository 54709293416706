import React from "react";
import "./style.css";
import RedHeart from "../../assets/img/red_heart.svg";
import { Link } from "react-router-dom";

const OuterFooter = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-wrapper">
          <div className="copyWrite-box">
            <p className="copywrite-text">
              © 2024 NeuroBridge Tech All rights reserved.
            </p>
            <ul className="btn-list">
              <li className="btn-item">
                <Link to="/tos" className="btn-text">
                  Terms of Service
                </Link>
              </li>
              <li className="btn-item">
                <Link to="/privacy" className="btn-text">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <h6 className="made-with-text">
            <img src={RedHeart} alt="icon" />
            Made with passion in India
          </h6>
        </div>
      </div>
    </footer>
  );
};

export default OuterFooter;
