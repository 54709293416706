import React, { useEffect, useState } from "react";
import CustomLink from "../components/CustomButton/CustomLink";
import Header from "../components/Header/Header";
import OuterFooter from "../components/Footer/OuterFooter";

const TermsOfService = () => {
  const [mobNavbar, setMobNavbar] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header mobNavbar={mobNavbar} setMobNavbar={setMobNavbar} />
      <section className="priTos-wrapper mb-5">
        <div className="container px-0">
          <div className="card priTos-card">
            <div className="card-header px-4 py-3 d-flex align-items-center">
              <h2 className="title">Terms of Service</h2>
            </div>
            <div className="card-body px-4 py-3">
              <h3 className="card-title">Effective Date: August 30, 2024</h3>
              <p className="card-text">
                Welcome to Chanakya, a service provided by Neurobridge Tech
                Private Limited ("Neurobridge," "we," "our," or "us"). By
                accessing or using our website, applications, and services
                (collectively, "Services"), you agree to comply with and be
                bound by these Terms of Service ("Terms"). Please read them
                carefully.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">1. Acceptance of Terms</h3>
              <p className="card-text">
                By accessing or using our Services, you agree to these Terms and
                our Privacy Policy. If you do not agree, please do not use our
                Services.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">2. Changes to Terms</h3>
              <p className="card-text">
                We may modify these Terms from time to time. Any changes will be
                posted on this page, and we may or may not notify you of
                significant changes. Your continued use of the Services after
                any modifications constitutes your acceptance of the revised
                Terms.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">3. Use of Services</h3>
              <h4 className="card-subtitle">Eligibility:</h4>
              <p className="card-text">
                You must be at least 13 years old to use our Services. If you
                are under 18, you must have your parent or guardian’s permission
                to use our Services.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Account Registration:</h4>
              <p className="card-text">
                To access certain features, you may need to create an account.
                You agree to provide accurate, current, and complete information
                during the registration process and to update such information
                as necessary to keep it accurate, current, and complete. You are
                responsible for maintaining the confidentiality of your account
                credentials and for all activities that occur under your
                account.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Prohibited Conduct:</h4>
              <p className="card-text">You agree not to:</p>
              <p className="card-text ps-4">
                Use the Services for any unlawful purpose or in violation of any
                local, state, national, or international law;
              </p>
              <p className="card-text ps-4">
                Post or share any content that is harmful, abusive, defamatory,
                obscene, or otherwise objectionable;
              </p>
              <p className="card-text ps-4">
                Impersonate any person or entity, or falsely state or otherwise
                misrepresent your affiliation with a person or entity;
              </p>
              <p className="card-text ps-4">
                Interfere with or disrupt the Services or servers or networks
                connected to the Services;
              </p>
              <p className="card-text ps-4">
                Engage in any conduct that restricts or inhibits any other user
                from using or enjoying the Services, or that is immoral or
                otherwise not appropriate;
              </p>
              <p className="card-text ps-4">
                Generate spam, NSFW (Not Safe For Work) content, sensitive
                information, misinformation, or any other content that violates
                these Terms or applicable laws;
              </p>
              <p className="card-text ps-4">
                Abuse, harm, interfere with, or disrupt our services or systems,
                including by introducing malware, spamming, hacking, or
                bypassing our systems or protective measures;
              </p>
              <p className="card-text ps-4">
                Engage in jailbreaking, adversarial prompting, prompt injection,
                or any other attempts to manipulate the system, except as
                authorised by us;
              </p>
              <p className="card-text ps-4">
                Access or use our services or content in fraudulent or deceptive
                ways, including:
              </p>
              <p className="card-text ps-4">
                Phishing; Creating fake accounts or content, including fake
                reviews;
              </p>
              <p className="card-text ps-4">
                Misleading others into thinking that generative AI content was
                created by a human; Providing services that appear to originate
                from you (or someone else) when they actually originate from us;
              </p>
              <p className="card-text ps-4">
                Providing services that appear to originate from us when they do
                not; Use our services (including the content that they provide)
                to violate anyone's legal rights, such as intellectual property
                or privacy rights;
              </p>
              <p className="card-text ps-4">
                Reverse engineer our services or underlying technology, such as
                our machine learning models, to extract trade secrets or other
                proprietary information, except as allowed by applicable law;
              </p>
              <p className="card-text ps-4">
                Use automated means to access content from any of our services
                in violation of the machine-readable instructions on our web
                pages (for example, robots.txt files that disallow crawling,
                training, or other activities);
              </p>
              <p className="card-text ps-4">
                Use AI-generated content from our services to develop machine
                learning models or related AI technology; Hide or misrepresent
                who you are in order to violate these terms; Provide services
                that encourage others to violate these terms.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">4. User Responsibilities</h3>
              <h4 className="card-subtitle">Data Usage and Storage:</h4>
              <p className="card-text">
                You are responsible for ensuring that your use of the Services,
                including any data you upload or store, complies with all
                applicable laws and regulations. Misuse of data or the Services
                may result in termination of your account.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Device Compatibility:</h4>
              <p className="card-text">
                It is your responsibility to ensure that the device you use to
                access the Services meets the minimum system requirements. We
                are not liable for any issues arising from incompatibility or
                failure to update your device.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">
                Content Moderation and Filtering:
              </h4>
              <p className="card-text">
                Chanakya AI employs automated content moderation and filtering
                systems to prevent the generation and distribution of content
                that violates these Terms, including but not limited to NSFW
                content, misinformation, and other prohibited conduct. While we
                strive to ensure the effectiveness of these systems, Neurobridge
                is not responsible for any content that may slip through the
                filters or for any failures in the moderation process.
                Neurobridge may review and remove content that is flagged by
                these systems or by users, but we do not guarantee that all
                inappropriate content will be caught or removed. Users are
                encouraged to report any content they believe violates these
                Terms.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">5. Content and Intellectual Property</h3>
              <h4 className="card-subtitle">Your Content:</h4>
              <p className="card-text">
                You retain ownership of any content you submit through the
                Services. By submitting content, you grant Neurobridge a
                worldwide, non-exclusive, royalty-free license to use,
                reproduce, modify, adapt, publish, and display such content in
                connection with providing the Services. You are responsible for
                ensuring that the content you submit does not violate any laws
                or the rights of others.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Our Content:</h4>
              <p className="card-text">
                All content provided by Neurobridge through the Services,
                including text, graphics, logos, and software, is the property
                of Neurobridge and is protected by intellectual property laws.
                You may not use, reproduce, or distribute any such content
                without our prior written permission.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Third-Party Content:</h4>
              <p className="card-text">
                Our Services may include content from third parties. Neurobridge
                does not claim ownership of third-party content and is not
                responsible for its accuracy, legality, or appropriateness.
                Users are responsible for their interactions with third-party
                content.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">AI-Generated Content:</h4>
              <p className="card-text">
                Ownership and Licence: AI-generated content produced by Chanakya
                AI is owned by Neurobridge Tech. Users are granted a
                non-exclusive, royalty-free licence to use, reproduce, modify,
                and distribute the AI-generated content for their own commercial
                purposes, as long as such use complies with these Terms.
              </p>
              <p className="card-text">
                Restrictions on Use: Users are prohibited from selling,
                licensing, or transferring AI-generated content to third
                parties. Any commercial use must not violate applicable laws or
                these Terms. Neurobridge reserves the right to restrict or
                revoke this licence if the content is used inappropriately or in
                violation of these Terms.
              </p>
              <p className="card-text">
                Liability: Chanakya AI is a generative AI application, and the
                content generated by the app is automatically produced based on
                user input. Neurobridge is not responsible for any content
                generated by the app, including but not limited to errors,
                omissions, or any consequences arising from the use of such
                content.
              </p>
              <p className="card-text">
                Intellectual Property Infringement: Neurobridge takes
                intellectual property rights seriously. If you believe that
                AI-generated content produced by Chanakya AI infringes on your
                copyrights or trademarks, please contact us immediately. We will
                investigate and take appropriate action, including content
                removal or account suspension.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">6. Privacy</h3>
              <p className="card-text">
                Your use of the Services is also governed by our Privacy Policy,
                which can be found here.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">7. Subscriptions, Refunds, and Cancellations</h3>
              <h4 className="card-subtitle">Subscription Terms:</h4>
              <p className="card-text">
                Chanakya AI offers subscription plans that provide access to
                additional features and content. By purchasing a subscription,
                you agree to pay the applicable fees as displayed during the
                purchase process.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Billing and Payment:</h4>
              <p className="card-text">
                Subscriptions are billed on a recurring basis, either monthly or
                annually, depending on the plan you choose. Payments are
                processed through the respective app stores (Google Play Store
                or Apple App Store) according to their billing terms. You agree
                to provide current, complete, and accurate billing information.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Trial Periods:</h4>
              <p className="card-text">
                If a free trial is offered, it will convert to a paid
                subscription at the end of the trial period unless you cancel
                before the trial ends. Details of the trial period and
                conversion will be provided at the time of sign-up.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Cancellation Policy:</h4>
              <p className="card-text">
                You may cancel your subscription at any time through the
                subscription management settings in the Google Play Store or
                Apple App Store. Cancellation will take effect at the end of the
                current billing cycle, and you will not be charged for the
                following cycle. However, no refunds will be issued for the
                current billing period.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Refund Policy:</h4>
              <p className="card-text">
                Refunds for subscriptions are subject to the refund policies of
                the Google Play Store or Apple App Store. Neurobridge does not
                directly process refunds for subscriptions purchased through
                these platforms. If you are eligible for a refund, please
                contact the respective app store support.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Pricing Changes:</h4>
              <p className="card-text">
                Neurobridge reserves the right to change the pricing of
                subscription plans at any time. If there is a price increase, we
                will notify you at least 10 days in advance. The price increase
                will take effect at the beginning of your next billing cycle. If
                you do not agree to the new pricing, you must cancel your
                subscription before the start of the new billing cycle to avoid
                being charged at the new rate. Continuing to use the Services
                after the price change takes effect constitutes your agreement
                to the new pricing.
              </p>
              <p className="card-text">
                Impact on Refunds: If you cancel your subscription due to a price 
                increase, the cancellation will take effect at the end of the current
                billing cycle, and you will not be eligible for a refund for the current period. 
                Refunds will not be issued based on pricing changes once the new pricing has taken effect.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Account Suspension or Ban:</h4>
              <p className="card-text">
                Neurobridge reserves the right to suspend or ban any account
                that violates these Terms, including but not limited to
                generating spam, NSFW content, sensitive information,
                misinformation, abuse of the platform, or other misuse of the
                service. No refund will be provided in such cases. Account
                suspensions or bans can be appealed by contacting us via email
                at support@neurobridge.tech. The decision to suspend or ban an
                account, as well as the outcome of any appeal, is at the sole
                discretion of Neurobridge.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">8. Termination</h3>
              <p className="card-text">
                We may terminate or suspend your account or access to the
                Services at any time, with or without notice, for conduct that
                we believe violates these Terms or is harmful to other users,
                us, or third parties, or for any other reason.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Suspension or Termination of Access:</h4>
              <p className="card-text">
                Without limiting any of our other rights, Neurobridge may
                suspend or terminate your access to the Services or delete your
                account if any of the following occur:
              </p>
              <p className="card-text ps-4">
                You materially or repeatedly breach these Terms,
                service-specific additional terms, or policies;
              </p>
              <p className="card-text ps-4">
                We are required to do so to comply with a legal requirement or a
                court order;
              </p>
              <p className="card-text ps-4">
                We reasonably believe that your conduct causes harm or liability
                to a user, third party, or Neurobridge – for example, by
                hacking, phishing, harassing, spamming, misleading others, or
                scraping content that doesn’t belong to you.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">9. Disclaimers and Limitation of Liability</h3>
              <h4 className="card-subtitle">Disclaimers:</h4>
              <p className="card-text">
                The Services are provided on an "as-is" and "as-available"
                basis. Neurobridge makes no warranties, expressed or implied,
                regarding the Services, including but not limited to, the
                implied warranties of merchantability, fitness for a particular
                purpose, and non-infringement. We do not provide any warranty or
                guarantee that the Services will always be available,
                uninterrupted, or error-free. If you encounter any issues, you
                can contact us via the help and support page.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Professional Advice Disclaimer:</h4>
              <p className="card-text">
                You must not rely on the Services for medical, legal, financial,
                or other professional advice. Any content regarding those topics
                is provided for informational purposes only and is not a
                substitute for advice from a qualified professional.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Limitation of Liability:</h4>
              <p className="card-text">
                To the maximum extent permitted by law:
              </p>
              <p className="card-text ps-4">
                Neither we nor our affiliates, licensors, or partners will be
                liable for any indirect, incidental, special, consequential, or
                exemplary damages, including loss of profits, goodwill, use, or
                data, even if we have been advised of the possibility of such
                damages;
              </p>
              <p className="card-text ps-4">
                Our aggregate liability under these Terms will not exceed the
                lesser of the amount you paid for the service that gave rise to
                the claim during the 3 months preceding the claim or two
                thousand Indian Rupees (₹2000).
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">10. Indemnification</h3>
              <p className="card-text">
                You agree to indemnify and hold harmless Neurobridge and its
                affiliates, officers, agents, employees, and partners from and
                against any claims, liabilities, damages, losses, and expenses,
                including reasonable attorneys' fees, arising out of or in any
                way connected with your access to or use of the Services, your
                violation of these Terms, or your infringement of any rights of
                another.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">11. Governing Law</h3>
              <p className="card-text">
                These Terms will be governed by and construed in accordance with
                the laws of India, without regard to its conflict of law
                principles. Any disputes that cannot be resolved through
                arbitration will be subject to the exclusive jurisdiction of the
                courts located in Delhi, India.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Applicability to International Users:</h4>
              <p className="card-text">
                By using our Services, international users agree that these
                Terms will be governed by and construed in accordance with the
                laws of India. They further consent to the exclusive
                jurisdiction of the courts located in Delhi, India for the
                resolution of any disputes arising from or relating to these
                Terms.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Export Controls and International Use:</h4>
              <p className="card-text">
                Users agree to comply with all applicable export control laws
                and regulations. The Services may not be used in any country or
                by any individual subject to trade sanctions or embargoes
                imposed by the government of India or other applicable
                jurisdictions.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">12. General Terms</h3>
              <p className="card-text ps-4">
                Entire Agreement: These Terms constitute the entire agreement
                between you and Neurobridge regarding the use of the Services.
              </p>
              <p className="card-text ps-4">
                Waiver and Severability: If any provision of these Terms is
                found to be invalid or unenforceable, the remaining provisions
                will continue to be valid and enforceable. If you do not follow
                these Terms or the service-specific additional terms, and we do
                not take action right away, that does not mean we are giving up
                any rights that we may have, such as taking action in the
                future.
              </p>
              <p className="card-text ps-4">
                Assignment: You may not assign or transfer these Terms, by
                operation of law or otherwise, without Neurobridge's prior
                written consent. Any attempt by you to assign or transfer these
                Terms without such consent will be null and void. Neurobridge
                may freely assign or transfer these Terms without restriction.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">13. Contact Us</h3>
              <p className="card-text">
                If you have any questions or concerns about these Terms, please
                contact us at support@neurobridge.tech.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">14. Use of Services on Behalf of an Organization</h3>
              <p className="card-text">
                Many organizations, such as businesses, non-profits, and
                educational institutions, may use our services. To use our
                services on behalf of an organization:
              </p>
              <p className="card-text ps-4">
                An authorized representative of that organization must agree to
                these Terms.
              </p>
              <p className="card-text ps-4">
                Your organization’s administrator may assign an account to you
                to access our services. The administrator might require you to
                follow additional rules specific to your organization and may
                have the ability to access, monitor, modify, or disable your
                account.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">15. Communications and Feedback</h3>
              <h4 className="card-subtitle">
                Service Announcements and Information:
              </h4>
              <p className="card-text">
                To provide you with our services, we may send you service
                announcements and other relevant information. For more details
                on how we communicate with you, please refer to our Privacy
                Policy.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Feedback:</h4>
              <p className="card-text">
                If you choose to give us feedback, such as suggestions to
                improve our services, you agree that we may act on your feedback
                without any obligation to you.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">16. Service Availability and Performance</h3>
              <h4 className="card-subtitle">No Guarantee of Availability:</h4>
              <p className="card-text">
                While we strive to provide our Services without interruption, we
                do not guarantee that the Services will always be available,
                uninterrupted, or error-free. Services may be subject to delays,
                outages, or other issues due to a variety of factors, including
                maintenance, upgrades, or unforeseen circumstances.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Scheduled Maintenance:</h4>
              <p className="card-text">
                We may perform scheduled maintenance or updates to our Services,
                which may result in temporary interruptions. We will do our best
                to inform you in advance of any scheduled maintenance that could
                impact your use of the Services.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Model Training:</h4>
              <p className="card-text">
                By using the Services, users agree that their inputs and
                AI-generated content may be used by Neurobridge to train and
                improve the AI models, in a manner that complies with applicable
                privacy laws and ensures user confidentiality.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Force Majeure:</h4>
              <p className="card-text">
                We are not responsible for any failure or delay in our
                performance under these Terms due to causes beyond our control,
                including but not limited to natural disasters, war,
                cyber-attacks, strikes, or other events that could not
                reasonably be anticipated.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Contact for Issues:</h4>
              <p className="card-text">
                If you encounter any issues with the availability or performance
                of our Services, you can contact us via the help and support
                page. We will make reasonable efforts to address and resolve
                your concerns.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">17. Dispute Resolution</h3>
              <h4 className="card-subtitle">Arbitration Clause:</h4>
              <p className="card-text">
                In the event of a dispute arising out of or relating to these
                Terms or your use of the Services, both parties agree to resolve
                the dispute through binding arbitration rather than in court,
                except as otherwise provided by law. Arbitration will be
                conducted in accordance with the rules of the Arbitration and
                Conciliation Act, 1996.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Governing Law and Jurisdiction:</h4>
              <p className="card-text">
                These Terms will be governed by and construed in accordance with
                the laws of India, without regard to its conflict of law
                principles. Any disputes that cannot be resolved through
                arbitration will be subject to the exclusive jurisdiction of the
                courts located in Delhi, India.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Class Action Waiver:</h4>
              <p className="card-text">
                You agree that any dispute resolution proceedings will be
                conducted only on an individual basis and not in a class,
                consolidated, or representative action.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Exception for Small Claims:</h4>
              <p className="card-text">
                Notwithstanding the foregoing, either party may bring an
                individual action in small claims court for disputes or claims
                within the scope of that court’s jurisdiction.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">
                Dispute Resolution for Content-Related Issues:
              </h4>
              <p className="card-text">
                In the event of disputes related to AI-generated content, such
                as claims of copyright infringement or requests for content
                removal, Neurobridge will follow a defined procedure to address
                and resolve these issues. Users should contact
                support@neurobridge.tech with their concerns, and Neurobridge
                will investigate and take appropriate action.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">18. What You Can Do</h3>
              <p className="card-text">
                Subject to your compliance with these Terms, you may access and
                use our Services. In using our Services, you must comply with
                all applicable laws, as well as any policies, guidelines, or
                documentation we make available to you, including our Usage
                Policies, Sharing & Publication Policy, and other relevant
                guidelines.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">19. What You Cannot Do</h3>
              <p className="card-text">
                You may not use our Services for any illegal, harmful, or
                abusive activity. For example, you may not:
              </p>
              <p className="card-text ps-4">
                Use our Services in a way that infringes, misappropriates, or
                violates anyone’s rights;
              </p>
              <p className="card-text ps-4">
                Modify, copy, lease, sell, or distribute any of our Services;
              </p>
              <p className="card-text ps-4">
                Attempt to or assist anyone to reverse engineer, decompile, or
                discover the source code or underlying components of our
                Services, including our models, algorithms, or systems (except
                as prohibited by applicable law);
              </p>
              <p className="card-text ps-4">
                Automatically or programmatically extract data or output from
                our Services;
              </p>
              <p className="card-text ps-4">
                Represent that AI-generated content (“Output”) was
                human-generated when it was not;
              </p>
              <p className="card-text ps-4">
                Interfere with or disrupt our Services, including circumventing
                any rate limits or restrictions, or bypassing protective
                measures or safety mitigations;
              </p>
              <p className="card-text ps-4">
                Use the Output to develop models or services that compete with
                Chanakya AI.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">20. Software</h3>
              <p className="card-text">
                Our Services may allow you to download software, such as mobile
                applications, which may update automatically to ensure that you
                are using the latest version. Our software may include
                open-source software that is governed by its own licenses, which
                we will make available to you.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">21. Third-Party Services</h3>
              <p className="card-text">
                Our Services may include third-party software, products, or
                services (“Third-Party Services”) and some parts of our Services
                may include output from those services (“Third-Party Output”).
                Third-Party Services and Third-Party Output are subject to their
                own terms, and we are not responsible for them.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">22. Feedback</h3>
              <p className="card-text">
                We appreciate your feedback, and by providing it, you agree that
                we may use it without restriction or compensation to you.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">23. Similarity of Content</h3>
              <p className="card-text">
                Due to the nature of our Services and artificial intelligence
                generally, output may not be unique, and other users may receive
                similar output from our Services. Our ownership of output is
                limited to the specific user-generated context and does not
                extend to other users' output or any Third-Party Output.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">24. Our Use of Content</h3>
              <p className="card-text">
                We may use content that you provide to us to improve, develop,
                and maintain our Services, comply with applicable law, enforce
                our terms and policies, and ensure the safety of our Services.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">25. Accuracy and Appropriateness of Output</h3>
              <p className="card-text">
                Artificial intelligence and machine learning are rapidly
                evolving fields, and as such, output from our Services may not
                always be accurate, complete, or reliable. When using our
                Services, you understand and agree that:
              </p>
              <p className="card-text ps-4">
                Output may not always be accurate, and you should not rely on it
                as your sole source of truth or factual information, or as a
                substitute for professional advice;
              </p>
              <p className="card-text ps-4">
                Neurobridge reserves the right to update and modify the
                underlying AI models at any time. Such updates may affect the
                consistency, accuracy, or nature of the generated content.
                Neurobridge will strive to inform users of significant updates
                that may impact their experience.
              </p>
              <p className="card-text ps-4">
                You must evaluate output for accuracy and appropriateness for
                your specific use case, and human review may be required before
                using or sharing the output;
              </p>
              <p className="card-text ps-4">
                You must not use any output relating to an individual for
                purposes that could have legal or material consequences for that
                individual (e.g., making decisions related to credit,
                employment, housing, insurance, legal matters, or medical
                issues).
              </p>
              <p className="card-text ps-4">
                We use web search to provide real-time information, and this
                information might not always be accurate or up to date. We may
                rely on third-party services to fetch and provide this
                information.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">26. Views Represented in Output</h3>
              <p className="card-text">
                The output from our Services does not represent the views of
                Neurobridge Tech or its affiliates. If the output references any
                third-party products or services, this does not imply any
                endorsement by those third parties.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">27. Cancellation and Changes</h3>
              <h4 className="card-subtitle">Cancellation:</h4>
              <p className="card-text">
                You may cancel your paid subscription at any time. Payments are
                non-refundable, except as required by law. These Terms do not
                override any mandatory local laws regarding your cancellation
                rights.
              </p>
              <div className="py-2"></div>
              <h4 className="card-subtitle">Changes:</h4>
              <p className="card-text">
                We may change our prices from time to time. If we increase
                subscription prices, we will notify you at least 10 days in
                advance, and the price increase will take effect on your next
                renewal. You may cancel your subscription if you do not agree to
                the price increase.
              </p>
              <div className="py-2"></div>
              <h3 className="card-title">28. Limitation of Liability</h3>
              <p className="card-text">
                To the maximum extent permitted by law:
              </p>
              <p className="card-text ps-4">
                Neither we nor our affiliates, licensors, or partners will be
                liable for any indirect, incidental, special, consequential, or
                exemplary damages, including loss of profits, goodwill, use, or
                data, even if we have been advised of the possibility of such
                damages;
              </p>
              <p className="card-text ps-4">
                Our aggregate liability under these Terms will not exceed the
                lesser of the amount you paid for the service that gave rise to
                the claim during the 3 months preceding the claim or two
                thousand Indian Rupees (₹2000).
              </p>
            </div>
            <div className="card-footer px-4 py-3">
              <CustomLink
                linkText="Contact Us"
                LinkTo={"mailto:support@neurobridge.tech"}
              />
            </div>
          </div>
        </div>
      </section>
      <OuterFooter />
    </div>
  );
};

export default TermsOfService;
