import ReactGA from "react-ga4";

const TRACKING_ID = "G-1FXXD7PF3E";

export const initGA = () => {
  ReactGA.initialize(TRACKING_ID);
};

export const logPageView = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
};

export const logEvent = (category = "", action = "", label = "") => {
  if (category && action) {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
};

export const logException = (description = "", fatal = false) => {
  if (description) {
    ReactGA.exception({
      description,
      fatal,
    });
  }
};
