import Lenis from "lenis";
import { useEffect, useRef } from "react";

const UseLenis = ({ children }) => {
  const lenisRef = useRef();
  useEffect(() => {
    const lenis = new Lenis({
      easing: (t) => (t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2),
      smooth: true,
      smoothTouch: true,
      direction: "vertical",
      gestureDirection: "vertical",
      touchMultiplier: 2,
    });

    lenisRef.current = lenis;

    const onScroll = (time) => {
      lenis.raf(time);
      requestAnimationFrame(onScroll);
    };

    requestAnimationFrame(onScroll);

    return () => {
      lenis.destroy();
    };
  }, []);

  return <div>{children}</div>;
};

export default UseLenis;
