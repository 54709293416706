import React from "react";
import "./style.css";
import { useParallax } from "react-scroll-parallax";
import CustomLink from "../CustomButton/CustomLink";
import MobileOne from "../../assets/img/mobileOne.png";
import Mobile13Pro from "../../assets/img/mobile13pro.png";
import Mobile15Pro from "../../assets/img/mobile15Pro.png";
import OuterFooter from "./OuterFooter";

const Footer = ({ handleTryChanakyaButtonClick }) => {
  const { ref } = useParallax({ speed: 10 });
  return (
    <>
      <section className="footer-section" data-aos="fade-up">
        <div className="container">
          <div className="mobile-img-section" ref={ref}>
            <div className="img-one">
              <img src={MobileOne} alt="mobile" />
            </div>
            <div className="img-13pro">
              <img src={Mobile13Pro} alt="mobile" style={{ top: "0%" }} />
            </div>
            <div className="img-15pro">
              <img src={Mobile15Pro} alt="mobile" />
            </div>
          </div>
          <div className="title-box">
            <h2 className="chanakya-title">CHANAKYA</h2>
            <CustomLink
              LinkTo="https://chat.aichanakya.in/"
              linkText="Try Chanakya - It’s FREE"
              customClasses="w-100"
              onClick={handleTryChanakyaButtonClick}
            />
          </div>
        </div>
      </section>
      <OuterFooter />
    </>
  );
};

export default Footer;
