import Slider from "react-slick";
import "./style.css";
import CustomLink from "../CustomButton/CustomLink";
import AppleLogo from "../../assets/img/Apple_logo.svg";
import AndroidLogo from "../../assets/img/Android_logo.svg";
import Globe from "../../assets/img/globe.svg";
import phoneImg from "../../assets/img/mobileImage.png";
import blackTiltArrow from "../../assets/img/black_arrow.svg";
import QR_Code from "../../assets/img/qr_code.png";
import Right_Arrow from "../../assets/img/right_arrow.svg";

const Banner = ({ deviceType }) => {
  const settings = {
    dots: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 3000,
    autoplaySpeed: 500,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings2 = {
    dots: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: -1,
    initialSlide: 0,
    speed: 3000,
    autoplaySpeed: 500,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: -1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: -1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: -1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: -1,
        },
      },
    ],
  };

  const sliderCard1Data = [
    {
      text: "Write a Python script to automate sending daily email reports",
    },
    {
      text: "Create a content calendar for a TikTok account",
    },
    {
      text: "Create a personal webpage for me after asking me three questions",
    },
    {
      text: "Create a charter to start a film club",
    },
  ];
  const sliderCard2Data = [
    {
      text: "Create a charter to start a film club",
    },
    {
      text: "Write a Python script to automate sending daily email reports",
    },
    {
      text: "Create a content calendar for a TikTok account",
    },
    {
      text: "Create a personal webpage for me after asking me three questions",
    },
  ];

  return (
    <section className="landing-banner">
      <div className="banner-wrapper">
        <div className="content-main">
          <div className="content-box">
            <h2 className="banner-title">
              India's Own <br /> Multilingual AI Companion
            </h2>
            <p className="banner-text">
              Free and easy to use, Chanakya AI helps with <br />
              writing, learning, brainstorming, and more—just ask!
            </p>
            <div className="btn-box">
              <CustomLink
                LinkTo="https://chat.aichanakya.in/"
                linkText="Try Chanakya - It’s FREE"
              />
              <a
                href={
                  deviceType === "Windows"
                    ? "https://chat.aichanakya.in/"
                    : deviceType === "Android"
                    ? "https://play.google.com/store/apps/details?id=tech.neurobridge.chanakya"
                    : deviceType === "iOS"
                    ? "https://apps.apple.com/us/app/chanakya-ai/id6504797681"
                    : "/"
                }
                target="_blank"
                rel="noreferrer"
                className="download-app d-none d-md-block"
              >
                Download App
                <img src={Right_Arrow} alt="icon" />
              </a>
            </div>
            <div className="store-box">
            <a
                href={
                  deviceType === "Windows"
                    ? "https://chat.aichanakya.in/"
                    : deviceType === "Android"
                    ? "https://play.google.com/store/apps/details?id=tech.neurobridge.chanakya"
                    : deviceType === "iOS"
                    ? "https://apps.apple.com/us/app/chanakya-ai/id6504797681"
                    : "/"
                }
                target="_blank"
                rel="noreferrer"
                className="download-app mt-0 me-4 d-block d-md-none"
              >
                Download App
                <img src={Right_Arrow} alt="icon" />
              </a>
              <div className="d-flex align-items-center">
                <p className="store-text">Available On</p>
                <ul className="store-list">
                  <li className="store-item">
                    <a href="https://apps.apple.com/us/app/chanakya-ai/id6504797681" target="_blank" rel="noreferrer">
                      <img src={AppleLogo} alt="logo" />
                    </a>
                  </li>
                  <li className="store-item">
                    <a
                      href="https://play.google.com/store/apps/details?id=tech.neurobridge.chanakya"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={AndroidLogo} alt="logo" />
                    </a>
                  </li>
                  <li className="store-item">
                    <a href="https://chat.aichanakya.in/" target="_blank" rel="noreferrer">
                      <img src={Globe} alt="logo" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="sliding-mainCards">
            <div className="right-overlay"></div>
            <div className="slider-container">
              <Slider {...settings}>
                {sliderCard1Data?.map((data) => (
                  <div className="slider-card">
                    <div className="card-body">
                      <h3 className="slider-title">{data?.text}</h3>
                      <img src={blackTiltArrow} alt="icon" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="slider-container">
              <Slider {...settings2}>
                {sliderCard2Data?.map((data) => (
                  <div className="slider-card">
                    <div className="card-body">
                      <h3 className="slider-title">{data?.text}</h3>
                      <img src={blackTiltArrow} alt="icon" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <div className="image-container">
          <img src={phoneImg} className="phone-img" alt="phoneImage" />
          <div className="sliding-mainCards">
            <div className="right-overlay"></div>
            <div className="slider-container">
              <Slider {...settings}>
                {sliderCard1Data?.map((data) => (
                  <div className="slider-card">
                    <div className="card-body">
                      <h3 className="slider-title">{data?.text}</h3>
                      <img src={blackTiltArrow} alt="icon" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="slider-container">
              <Slider {...settings2}>
                {sliderCard2Data?.map((data) => (
                  <div className="slider-card">
                    <div className="card-body">
                      <h3 className="slider-title">{data?.text}</h3>
                      <img src={blackTiltArrow} alt="icon" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="app-qr-code">
        <img src={QR_Code} alt="code" />
        <p className="download-text">Download Chanakya AI</p>
      </div>
    </section>
  );
};

export default Banner;
